<template>
  <getecma-single-content-layout content-class="scroll pe--xxl">
    <template v-if="customer" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl" />
      <getecma-row justify="center" class="d-flex align-items--center d-xs-flex-column">
        <getecma-col :xs="24" :span="17" justify="left">
          <getecma-customer-view-information :customer="customer" />
        </getecma-col>
      </getecma-row>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getCustomerById } from '@/modules/customer/customer.service';
import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';

import GetecmaCustomerViewInformation from '@/modules/customer/components/CustomerViewComponent.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaCustomerViewPage',
  components: {
    GetecmaCustomerViewInformation,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Clientes', path: MY_SPACE_URL.customers.path },
        { name: 'Visualizar', path: this.$route.path },
      ],
      customer: null,
      pagination: {
        page: 1,
        limit: 5,
      },
    };
  },
  computed: {
    customer_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getCustomerById(this.customer_id)
        .then(data => {
          this.customer = data;
        })
        .catch(() => toastError('Erro ao obter cliente por ID'));
    },
  },
};
</script>
